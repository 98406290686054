let locale;

/*
  Format locale files like this:

    https://developer.mozilla.org/en-US/docs/Mozilla/Add-ons/WebExtensions/Internationalization

*/

function getLanguageFallback (language) {
  switch (language) {
  case 'nb':
    return 'nn';
  case 'nn':
    return 'nb';
  case 'dk':
    return 'nb';
  default: break;
  }

  return 'en';
}

/**
 * Fetches the locales for the provided language,
 * if available. Otherwise responds with english locale.
 *
 * @param {string} language Unicode locale identifier string
 */
async function getLocale (language) {
  let response = await fetch(`/_locales/${language}/messages.json`);

  if (!response.ok) {
    const fallbackLanguage = getLanguageFallback(language);

    response = await fetch(`/_locales/${fallbackLanguage}/messages.json`);
  }

  const json = await response.json();

  return json;
}

/**
 * The language of the browser UI.
 *
 * @returns {string} BCP47. Split before the dash.
 */
function getLanguageFromBrowser () {
  const navigatorLanguage = navigator.language;

  return navigatorLanguage.indexOf('-') !== -1 ? navigatorLanguage.split('-')[0] : navigatorLanguage;
}

/**
 * Get the translation from a key in the current lanugage of the browser UI.
 *
 * @param {string} key The locale key to get
 * @returns {Promise<string>} The translation
 */
export async function getTranslation (key) {
  const language = getLanguageFromBrowser();

  if (!locale) {
    // eslint-disable-next-line require-atomic-updates
    locale = getLocale(language);
  }

  const translation = await locale;

  return key in translation ? translation[key].message : key;
}

class i18nText extends HTMLElement {
  static get observedAttributes () {
    return ['key'];
  }

  async attributeChangedCallback (name, oldValue, newValue) {
    this.renderTranslation(newValue);
  }

  async renderTranslation (translationKey) {
    const text = await getTranslation(translationKey) || `[Missing translation for ${translationKey}]`;
    const textNode = document.createTextNode(text);

    if (this._sDOM) {
      [...this._sDOM.childNodes]
        .filter(node => !(node instanceof HTMLStyleElement))
        .forEach(node => node.remove());

      this._sDOM.appendChild(textNode);
    }
  }

  connectedCallback () {
    this._sDOM = this.attachShadow({ mode: 'closed' });
    this._sDOM.innerHTML = `
      <style>
      :host {
        display: contents;
      }
      </style>
    `;

    this.renderTranslation(this.getAttribute('key'));
  }
}

customElements.define('i18n-text', i18nText);
